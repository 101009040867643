import Axios from 'axios'
import type { AxiosError, AxiosHeaders, AxiosResponse } from 'axios'

export interface CommonResponse<T> {
  code: number
  message: string
  data: T
  extra: unknown
}

const api = Axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  function (config) {
    config.baseURL = '/api'

    if (!_global.isProd && _global.apiEnv === 'prod') {
      config.baseURL = '/prodapi'
    }

    config.headers['X-Token'] = _store.token

    config.headers['X-Client-Version'] = _global.clientVersion
    config.headers['X-Client-Type'] = _global.clientType
    config.headers['X-Client-Channel'] = _global.clientChannel

    const debugHeaderValues = []

    if (_db.debug.skip) {
      debugHeaderValues.push('skipVCode')
    }
    if (_db.debug.forceOrderPriceToZero) {
      debugHeaderValues.push('forceOrderPriceToZero')
    }
    if (_db.debug.skipEnergy) {
      debugHeaderValues.push('skipEnergy')
    }
    if (_store.debugTs) {
      debugHeaderValues.push(`futureTs=${_store.debugTs}`)
    }

    if (debugHeaderValues.length > 0) {
      config.headers['X-Debug'] = debugHeaderValues.join(',')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response: AxiosResponse) {
    const data = response.data

    if (typeof data === 'object') {
      Object.defineProperty(data, '__response', {
        value: response,
        enumerable: false,
      })
    }

    const h = response.headers as AxiosHeaders
    if (h.has('x-token')) {
      _store.token = h.get('x-token') as string
    }

    return data
  },
  // any status code which is not 2xx
  function (err: AxiosError) {
    // 通过 abort singal 取消请求的报错，此时不用做处理
    if (err.name === 'CanceledError') {
      throw err
    }

    // 460 表示注销中
    if (err.response?.status === 460) {
      _router.replace({
        name: 'terminating',
        query: {
          willTerminateAt: (err.response.data as any)?.data?.willTerminateAt,
        },
      })
      return
    }

    if (err.response?.status === 401 && !err.config?._ignore401) {
      _store.logout()
      _message.error(_t('api.401'))

      err.skipSentry = true

      throw err
    }

    // 400
    if (err.response?.status === 400) {
      _message.error('[400]' + _t('api.400'))
      throw err
    }

    // 429，调用次数超出限制
    if (err.response?.status === 429) {
      // 429 错误不用上报给 sentry
      err.skipSentry = true
      if (err.config?._custom429Msg) {
        _message.info(err.config._custom429Msg)
      } else {
        _message.error('[429]' + _t('api.429'))
      }
      throw err
    }

    // 500
    if (err.response?.status === 500) {
      _message.error('[500]' + _t('api.500'))
      throw err
    }

    // 网络连接失败
    if (err.code === 'ERR_NETWORK') {
      _message.error(_t('api.network_error'))

      throw err
    }

    _message.error(err.message!)
    throw err
  }
)

export default api
