<template>
  <div
    :id="`region-${region.index}`"
    class="relative"
  >
    <div class="region-title">
      {{ regionTitle(region) }}
    </div>

    <div class="region-stages relative">
      <div
        v-for="stage in region.stages"
        :key="stage.level"
        :class="[
          'h-70px flex items-center',
          `level-${((stage.level - 1) % 10) + 1}`,
        ]"
      >
        <div
          v-if="stage.completed"
          class="cursor-pointer relative"
          :class="getStageClass(region, stage)"
          :style="getStageStyle(region, stage)"
          @click="onClickCompletedStage(stage)"
          @touchstart="onTouchStart"
        >
          <PkgIcon
            v-if="stage.atlasStage!.pkgInfo"
            :style="stage.atlasStage!.pkgInfo.style"
            class="w-38px h-38px text-32px"
          />

          <div class="id">{{ stage.level }}</div>

          <LottieBox
            v-if="region.received"
            name="shine"
            class="absolute absolute-center h-full aspect-square pointer-events-none"
            autoplay
            loop
          />
        </div>

        <div
          v-else
          class="cursor-pointer relative"
          :class="getStageClass(region, stage)"
          :style="getStageStyle(region, stage)"
          @click="onClickStage(stage)"
          @touchstart="onTouchStart"
        >
          <div
            v-if="stage.active"
            class="anchor"
          >
            <svg
              :style="getStageAnchorStyle(region)"
              class="anchor-icon"
              width="53"
              height="70"
              viewBox="0 0 53 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.25 70C26.25 70 52.5 49 52.5 26.25C52.5 11.7525 40.7475 0 26.25 0C11.7525 0 0 11.7525 0 26.25C0 49 26.25 70 26.25 70Z"
              />
            </svg>

            <Avatar
              v-if="store.user"
              :avatar="store.user.avatar"
              imgStyle="s"
              class="avatar"
            />
          </div>

          {{ stage.level }}

          <LottieBox
            v-if="region.received"
            name="shine"
            class="absolute aspect-square h-full absolute-center"
            autoplay
            loop
          />
        </div>
      </div>

      <template
        v-for="challengeStage in region.challengeStages"
        :key="`challenge-${challengeStage.index}-${challengeStage.star}`"
      >
        <div
          :id="`challenge-stage-${challengeStage.index}`"
          class="absolute flex flex-col items-center w-100px cursor-pointer"
          :style="getChallengeStageStyle(challengeStage)"
          @click="onClickChallengeStage(region, challengeStage)"
        >
          <div class="relative flex flex-col items-center">
            <div
              :style="getChallengeStageBossStyle(challengeStage)"
              class="z-1"
            >
              <Img
                :name="challengeStage.npcImage"
                :class="{
                  bounce:
                    currentBounceChallengeStageIndex === challengeStage.index,
                }"
              />
            </div>
            <div class="absolute bottom--14px">
              <Img name="npc-shadow" />
            </div>
          </div>

          <div
            v-if="challengeStage.active"
            class="flex items-center py-2px px-4px rounded-4px bg-neutral-900 bg-opacity-60 w-fit"
          >
            <Icon
              :name="challengeStage.star > 0 ? 'light-star' : 'dark-star'"
              class="w-14px"
            />
            <Icon
              :name="challengeStage.star > 1 ? 'light-star' : 'dark-star'"
              class="w-14px"
            />
            <Icon
              :name="challengeStage.star > 2 ? 'light-star' : 'dark-star'"
              class="w-14px"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { type Region, type Stage, type ChallengeStage } from './Atlas.vue'
import { first, last } from 'lodash-es'
import { vibrate } from '@/utils'
import { useCommonStore } from '@/stores'
import LottieBox from '@/components/LottieBox.vue'
import PkgIcon from './PkgIcon.vue'

const store = useCommonStore()

const emits = defineEmits<{
  clickStage: [stage: Stage]
  clickCompletedState: [stage: Stage]
  clickChallengeStage: [region: Region, stage: ChallengeStage]
}>()

const { region, active } = defineProps<{
  region: Region
  // 当前 region 是否是 active region
  active: boolean
}>()

// 挑战关卡怪兽跳动效果
const currentBounceChallengeStageIndex = ref<number>()
{
  let timer: number
  function scheduleChallengeBounce(region: Region) {
    clearTimeout(timer)
    currentBounceChallengeStageIndex.value = getNextBounceChallengeIndex(
      region,
      currentBounceChallengeStageIndex.value
    )

    timer = setTimeout(() => {
      scheduleChallengeBounce(region)
    }, 3000)
  }
  function getNextBounceChallengeIndex(
    region: Region,
    start?: number
  ): number | undefined {
    if (start == null) {
      const firstChallenge = region.challengeStages.find(
        item => item.lastCompletedAt == null && item.active
      )
      return firstChallenge?.index
    }

    const startIndex = region.challengeStages.findIndex(
      item => item.index === start
    )
    const reorderChallengeStages = region.challengeStages
      .slice(startIndex + 1)
      .concat(region.challengeStages.slice(0, startIndex + 1))

    return reorderChallengeStages.find(
      item => item.lastCompletedAt == null && item.active
    )?.index
  }
  watch(
    () => active,
    active => {
      if (active) {
        scheduleChallengeBounce(region)
      } else {
        currentBounceChallengeStageIndex.value = undefined
      }
    },
    { immediate: true }
  )
}

function regionTitle(region: Region): string {
  const firstStage = first(region.stages)
  const lastStage = last(region.stages)

  return _t('atlas.region_title', {
    region: region.index + 1,
    firstLevel: firstStage?.level,
    lastLevel: lastStage?.level,
  })
}

function getStageClass(region: Region, stage: Stage): string {
  const classList = ['stage']

  if (stage.active) {
    classList.push(...['active', 'anime-button'])
  } else if (stage.completed) {
    classList.push(...['completed', 'anime-button'])
  }

  if (region.received) {
    classList.push(...['received', 'g-premium-bg'])
  }

  return classList.join(' ')
}

function getStageStyle(region: Region, stage: Stage): string {
  const styleList: string[] = []

  if (region.received) {
    styleList.push(`box-shadow: 0px 8px var(--yellow-600)`)
  } else if (stage.active || stage.completed) {
    styleList.push(`background-color: var(--${region.color}-500)`)
    styleList.push(`box-shadow: 0px 8px var(--${region.color}-600)`)
  } else {
    styleList.push(`box-shadow: 0px 8px var(--surface-400)`)
    styleList.push('background-color: var(--surface-300);')
  }

  return styleList.join(';')
}

function onClickCompletedStage(stage: Stage) {
  emits('clickCompletedState', stage)
}

function onClickStage(stage: Stage) {
  emits('clickStage', stage)
}

function onTouchStart() {
  vibrate()
}

function getStageAnchorStyle(region: Region): string {
  const styleList: string[] = []
  const color = region.received ? 'amber' : region.color

  styleList.push(`filter: drop-shadow(0px 1px 2px var(--${color}-600));`)
  styleList.push(`fill: var(--${color}-200);`)

  return styleList.join('')
}

function getChallengeStageStyle(challengeStage: ChallengeStage) {
  const index = challengeStage.index - 1
  const styleList: string[] = []

  switch (index % 3) {
    case 0:
      styleList.push('right: 24px; bottom: 210px;')
      break
    case 1:
      styleList.push('left: 24px; bottom: 500px;')
      break
    case 2:
      styleList.push('right: 24px; bottom: 800px;')
  }

  if (!challengeStage.active) {
    styleList.push('filter: grayscale(100%);')
  }

  return styleList.join('')
}

async function onClickChallengeStage(
  region: Region,
  challengeStage: ChallengeStage
) {
  emits('clickChallengeStage', region, challengeStage)
}

function getChallengeStageBossStyle(challengeStage: ChallengeStage) {
  // 先拿到每个挑战关卡在自己区域的相对 index
  const relativeIndex = challengeStage.index % 3
  // 每个区域 2 号位 boss 图片，水平翻转
  // 我们准备的图片，默认会“向左看”
  // 然后让 2 号位置的 boss 翻转“向右看”
  if (relativeIndex % 2 === 0) {
    return 'transform: scaleX(-1);'
  }
}
</script>

<style scoped>
.region-title {
  color: var(--text-color-secondary);
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  padding: 16px;
  text-align: center;
}

.region-title :deep(.p-divider-content) {
  background-color: var(--ld-background);
}

.stage {
  font-family: 'DIN';
  width: 70px;
  height: 58px;
  flex-shrink: 0;
  font-size: 32px;
  font-weight: 700;
  border-radius: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}
.stage:active {
  box-shadow: unset !important;
  position: relative;
  top: 8px;
}

.stage .anchor {
  width: 52px;
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.anchor {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 0px solid #ccc; /* 边框用于定位 */
  padding: 10px;
}

/* 使用伪元素来添加叠加色效果 */
.anchor::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 50%;
}

.stage.active .avatar {
  font-size: 24px;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.stage.completed {
  flex-direction: column;
}

.stage.received {
  color: var(--yellow-800);
}

.stage.received .id {
  color: white;
}

.stage.completed .id {
  height: 22px;
  width: 20px;
  font-size: 14px;
  font-weight: 700;
  font-family: DIN;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.region-stages {
  max-width: 375px;
  margin: 0px auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 40px 32px;
}

.level-1 {
  padding-left: 48.8%;
  margin-top: 24px;
}
.level-2 {
  padding-left: 16%;
  margin-top: 46px;
}
.level-3 {
  padding-left: 0px;
  margin-top: 22px;
}
.level-4 {
  padding-left: 33.4%;
  margin-top: 18px;
}
.level-5 {
  padding-left: 67.2%;
  margin-top: 43px;
}

.level-6 {
  justify-content: flex-end;
  margin-top: 23px;
}
.level-7 {
  padding-left: 48.8%;
  margin-top: 22px;
}
.level-8 {
  padding-left: 13%;
  margin-top: 54px;
}
.level-9 {
  padding-left: 0px;
  margin-top: 25px;
}
.level-10 {
  padding-left: 33.4%;
}

.bounce {
  animation: bounce 3s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
  10% {
    transform: translateY(-12px) scaleY(1.05) scaleX(0.95);
  }
  20% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
  30% {
    transform: translateY(-12px) scaleY(1.05) scaleX(0.95);
  }
  40% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
}
</style>
