<template>
  <div class="atlas-header">
    <div
      v-if="store.energy"
      class="flex items-center cursor-pointer"
      @click="onEnergyClick"
    >
      <Icon
        name="energy"
        class="w-30px z-1"
      />

      <ProgressBar
        :percent="energyPercent"
        color="linear-gradient(269.98deg, var(--yellow-600) 0.01%, var(--yellow-500) 99.98%)"
        :highlight="false"
        :lottie="false"
        :text="`${store.energy.currentEnergy}/${store.energy.energyLimit}`"
        :border-radius="26"
        class="energy"
      />
    </div>

    <div class="ml-auto flex items-center gap-3">
      <div
        class="flex cursor-pointer items-center"
        @click="onHotDaysClick"
      >
        <div class="relative top-2px z-1">
          <Icon
            :name="hotDaysIcon"
            class="w-28px"
          />

          <Icon
            v-if="showCheckInTip"
            name="check-in-tip"
            class="w-18px absolute right--2px top-0"
          />
        </div>

        <div
          class="hot-days-count"
          :style="{
            fontSize: isContinuousDaysFontSizeSmaller ? '14px' : '16px',
          }"
        >
          {{ continuousDays }}
        </div>
      </div>

      <DiamondCount clickable></DiamondCount>
      <DotBadge
        :visible="store.showAlphaGift || diamondCardNotClaimedToday"
        class="flex items-center cursor-pointer"
        :dotStyle="{
          top: 0,
          right: 4,
        }"
      >
        <Icon
          name="store"
          class="w-30px"
          @click="onStoreClick"
        />
      </DotBadge>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import CheckIn from '../CheckIn.vue'
import { ref, onActivated, computed } from 'vue'
import ProgressBar from '@/components/ProgressBar.vue'
import DiamondCount from '@/components/DiamondCount.vue'
import DotBadge from '@/components/DotBadge.vue'
import { openStore, showEnergyDialog } from '@/shared'

const store = useCommonStore()

const rollerKey = ref(0)
const continuousDays = computed(() => {
  return Math.min(store.checkInCtRes?.continuousDays ?? 0, 9999)
})

// 是否需要展示更小的字号
const isContinuousDaysFontSizeSmaller = computed(
  () => continuousDays.value.toString().length > 3
)

const energyPercent = computed(() => {
  if (store.energy == null) return 0

  return Math.min(
    (store.energy.currentEnergy / store.energy.energyLimit) * 100,
    100
  )
})

const hotDaysIcon = computed(() => {
  if (store.checkInCtRes == null) return 'gray-border-fire'

  return store.checkInCtRes.todayCheckIn ? 'border-fire' : 'gray-border-fire'
})

const showCheckInTip = computed(() => {
  if (store.checkInCtRes == null) return false

  return (
    store.checkInCtRes.continuousDays > 0 && !store.checkInCtRes.todayCheckIn
  )
})

// 有钻石卡，且今天未领取
const diamondCardNotClaimedToday = computed(() => {
  return store.diamondCard != null && !store.diamondCard.receivedToday
})

// TODO(buding): roller 这个组件有个 bug，学习完成后返回时，roller item 宽度会变成 16
// 这里是为了重新让 roller 渲染
onActivated(() => {
  rollerKey.value++
})

function onEnergyClick() {
  showEnergyDialog()
}

function onHotDaysClick() {
  _openDialog(CheckIn, {
    rootClass: 'w-full !max-w-420px',
    fullscreenInMobile: true,
    dialog: {
      showHeader: false,
      pt: {
        content: { style: 'padding: 0px;' },
      },
    },
  })
}

function onStoreClick() {
  openStore()
}
</script>

<style scoped>
.atlas-header {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0px 16px;
  height: 36px;
}

.energy {
  width: 68px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  font-size: 16px;
  font-family: DIN;
  margin-left: -4px;
  border-radius: 0px 26px 26px 0px;
  background-color: var(--ld-energyBg);
}

.energy :deep(*) {
  color: white !important;
}

.energy > :deep(div:first-child) {
  border-radius: 0px 26px 26px 0px !important;
}

.hot-days-count {
  width: 58px;
  height: 24px;
  background: var(--ld-energyBg);
  border: 2px solid white;
  color: white;
  font-family: DIN;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -16px;
  padding-left: 4px;
  border-radius: 0px 26px 26px 0px;
}
</style>
