<template>
  <Loading
    v-if="loading"
    class="h-full"
  />

  <div
    v-else
    class="h-full flex flex-col"
  >
    <div class="hstack w-full items-center justify-center flex-1">
      <div class="flex images">
        <Img name="role-2" />
        <Img name="role-1" />
        <Img name="role-4" />
        <Img name="role-3" />
        <Img name="role-7" />
      </div>

      <div class="ml-80px min-w-356px">
        <div class="text-3xl mb-2">{{ _t('landing.start2') }}</div>
        <div class="text-2xl mb-8 text-[var(--surface-400)]">
          {{ _t('msg1') }}
        </div>

        <Button
          class="w-full mb-4"
          :label="_t('landing.start')"
          @click="onAuth"
        ></Button>

        <DebugButton
          label="访客登录"
          class="w-full"
          @click="debugGuestLogin(router, route)"
        ></DebugButton>
      </div>
    </div>

    <CopyrightFooter class="mx-auto mb-8" />
  </div>
</template>
<script setup lang="ts">
import CopyrightFooter from '@/components/CopyrightFooter.vue'
import { debugGuestLogin } from '@/debug'
import { useLandingLogic } from '@/shared/landing'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const loading = useLandingLogic(router, route)

function onAuth() {
  router.push({
    name: 'auth',
    query: {
      redirect: route.query.redirect || undefined,
    },
  })
}
</script>
<style scoped>
.images :deep(img) {
  width: 108px;
}
.images :deep(img:nth-child(2n)) {
  position: relative;
  bottom: 40px;
}
</style>
