<template>
  <div
    :class="[
      'h-full bg-ld-background',
      {
        'big-screen': viewSize.width > 1000,
      },
    ]"
    ref="root"
  >
    <Loading
      v-if="loading"
      class="h-full"
    />

    <div
      v-else
      :class="[
        'h-full main overflow-hidden',
        {
          'px-6 pt-6': _global.isPcMode,
        },
      ]"
    >
      <div class="w-full h-full flex flex-col max-w-750px flex-1 mx-auto">
        <SafeAreaTopSpacer v-if="_global.isMobileMode" />

        <Header class="mb-1" />

        <div
          class="region-header relative"
          :style="activeRegion != null ? getRegionStyle(activeRegion) : ''"
          :class="{
            'g-premium-bg': activeRegion?.received,
          }"
        >
          <template v-if="activeRegion">
            <span
              class="text-21px font-semibold text-white"
              :class="{ 'text-yellow-800': activeRegion.received }"
            >
              {{ regionTitle(activeRegion) }}
            </span>

            <OverlayPanel ref="rewardOverlay">
              <div class="w-240px">
                <div class="flex items-center">
                  <Icon
                    name="light-star"
                    class="w-36px"
                  />
                  <ProgressBar
                    :percent="getRegionPercent(activeRegion)"
                    color="linear-gradient(92.6deg, var(--amber-100) -16.25%, var(--amber-400) 142.19%)"
                    :text="`${getRegionStar(activeRegion)}/${getRegionTotalStar(
                      activeRegion
                    )}`"
                  />
                </div>
                <div class="text-17px">
                  通过
                  <span class="font-bold">挑战关卡</span>
                  收集 ⭐
                </div>
                <div class="text-17px">收集满全部 ⭐️ 后可领取奖励</div>
              </div>
            </OverlayPanel>

            <template v-if="showRegionRewardButton()">
              <RewardButton
                v-if="!activeRegion.received"
                class="ml-auto"
                :star="getRegionStar(activeRegion)"
                :maxStar="getRegionTotalStar(activeRegion)"
                @click="onRegionRewardReceive"
              />

              <Icon
                v-else
                name="cup-gold"
                class="ml-auto w-38px h-38px"
              />
            </template>
          </template>

          <template v-if="activeRegion?.received">
            <LottieBox
              name="shine"
              class="absolute left-0 aspect-square h-full pointer-events-none"
              autoplay
              loop
            />
            <LottieBox
              name="shine"
              class="absolute right-0 aspect-square h-full pointer-events-none"
              autoplay
              loop
            />
          </template>
        </div>

        <div class="regions-main">
          <RecycleScroller
            :key="scrollerKey"
            v-slot="{ item }"
            ref="scroller"
            class="h-full"
            :items="scrollItems"
            key-field="index"
            @scroll="onScroll"
          >
            <UnlockRegion
              v-if="item.type === 'unlock'"
              :region="latestRegion"
              :stage="latestRegionLastStage"
            />

            <Region
              v-if="item.type == 'region'"
              :region="item"
              :active="item.index === activeRegion?.index"
              @click-stage="onClickStage"
              @click-challenge-stage="onClickChallengeStage"
              @click-completed-state="onClickCompletedStage"
            />

            <StartHere v-if="item.type === 'startHere'" />
          </RecycleScroller>

          <div
            v-if="focusBtn !== 'none'"
            class="focus-btn"
            @click="onClickFocusBtn"
          >
            <Icon
              :name="`stage-arrow-${focusBtn}`"
              class="w-28px"
            />
          </div>
        </div>
      </div>

      <div class="w-350px hidden tasks">
        <Header class="hidden tasks-header" />

        <div class="daily-tasks">
          <div class="flex items-center px-4 pt-4 justify-between mb-2">
            <div class="text-ld-text text-17px font-semibold">
              {{ _t('atlas.daily_reward') }}
            </div>
            <div
              class="text-ld-brand-500 text-15px font-semibold flex items-center cursor-pointer"
              @click="onGetMoreRewards"
            >
              {{ _t('atlas.more_reward') }}
              <i class="pi pi-chevron-right"></i>
            </div>
          </div>

          <DailyTasks
            v-if="taskStats"
            :tasks="taskStats.daily.tasks"
            :stats="taskStats.daily.stats"
            class="task-list"
          />
        </div>
      </div>
    </div>

    <div
      v-if="selectedChallengeStage"
      class="absolute top-0 left-0 h-full w-full flex flex-col bg-white z-1 p-4 g-safe-area"
    >
      <SafeAreaTopSpacer v-if="_global.isMobileMode" />
      <div class="g-header-width">
        <Icon
          name="close"
          class="w-22px"
          @click="selectedChallengeStage = undefined"
        />
      </div>

      <RatioSpacedContainer class="p-4 flex-1">
        <div class="flex gap-4">
          <Icon
            :name="
              selectedChallengeStage.star > 0
                ? 'challenge-light-star'
                : 'challenge-dark-star'
            "
            class="w-46px"
          />
          <Icon
            :name="
              selectedChallengeStage.star > 1
                ? 'challenge-light-star'
                : 'challenge-dark-star'
            "
            class="relative bottom-6 w-46px"
          />
          <Icon
            :name="
              selectedChallengeStage.star > 2
                ? 'challenge-light-star'
                : 'challenge-dark-star'
            "
            class="w-46px"
          />
        </div>

        <Img
          :name="selectedChallengeStage.npcImage"
          class="w-200px"
        />

        <div class="text-22px mt-6">
          {{ challengeMessageMap[selectedChallengeStage.star] }}
        </div>
      </RatioSpacedContainer>

      <div class="g-header-width flex flex-col items-center relative">
        <DebugLabel class="flex gap-4 absolute bottom-24 left-0">
          <DebugButton
            label="一星"
            :loading="challengeStageStartLoading"
            @click="onDebugChallengeStageFinish(selectedChallengeStage, 1)"
          ></DebugButton>
          <DebugButton
            label="二星"
            :loading="challengeStageStartLoading"
            @click="onDebugChallengeStageFinish(selectedChallengeStage, 2)"
          ></DebugButton>
          <DebugButton
            label="三星"
            :loading="challengeStageStartLoading"
            @click="onDebugChallengeStageFinish(selectedChallengeStage, 3)"
          ></DebugButton>
        </DebugLabel>

        <ExpInfo
          :exp="preChallengeStageInfo.exp"
          class="mb-2"
        />
        <Button
          class="w-full mb-4"
          @click="onChallengeStageStart(selectedChallengeStage)"
        >
          <div class="flex justify-center w-full font-bold items-center">
            <span>{{ _t('atlas.start_challenge') }}</span>

            <EnergyCount
              :count="preChallengeStageInfo.requiredEnergy"
              class="ml-2"
            />
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import PkgIcon from './PkgIcon.vue'
import PkgSelector from './PkgSelector.vue'
import { ref, nextTick, computed } from 'vue'
import Icon from '@/components/Icon.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import EnergyCount from '@/components/EnergyCount.vue'
import ExpInfo from '@/components/ExpInfo.vue'
import Region from './Region.vue'
import StartHere from './StartHere.vue'

import {
  fetchAtlasStages,
  startAtlasChallengeStage,
  type Stage as AtlasStage,
  type ChallengeStage as AtlasChallengeStage,
  fetchAtlasChallengeStages,
  fetchAtlasRegionReward,
  fetchAtlasRegionRewardStatus,
  type ChallengeStagePreInfo,
  preStartAtlasChallengeStage,
} from '@/api/atlas'
import { first, last } from 'lodash-es'
import StageReview from './StageReview.vue'
import { useRoute, useRouter } from 'vue-router'
import { Code } from '@/api/code'
import { debugFinishChallengeStage } from '@/debug'
import RewardButton from '@/components/RewardButton.vue'
import Header from './Header.vue'
import DailyTasks from '@/mobile/pages/Reward/DailyTasks.vue'
import { fetchTasks, RewardType, type TaskStats } from '@/api/task'
import { onActivated, onMounted, onUnmounted } from 'vue'
import { watch } from 'vue'
import bus, { BusEvent } from '@/bus/bus'
import LottieBox from '@/components/LottieBox.vue'
import RecommendDialog from '@/components/RecommendDialog.vue'
import { checkNotificationGuide } from '@/utils/notification'
import { showEnergyDialog } from '@/shared'
import UnlockRegion from './UnlockRegion.vue'

const router = useRouter()
const route = useRoute()

const viewSize = window._viewSize

const challengeMessageMap: Record<number, string> = {
  0: _t('atlas.challenge_star_0'),
  1: _t('atlas.challenge_star_1'),
  2: _t('atlas.challenge_star_2'),
  3: _t('atlas.challenge_star_3'),
}

export interface Stage {
  level: number
  active: boolean
  completed: boolean
  atlasStage?: AtlasStage
}

export interface ChallengeStage {
  // 是否可以挑战该关卡
  active: boolean

  star: number

  // 1 base
  index: number

  level: number
  npcImage: string

  lastCompletedAt?: string
}

export interface Region {
  // 0 base
  index: number
  color: (typeof REGION_COLORS)[number]
  stages: Stage[]
  challengeStages: ChallengeStage[]
  // 领取过奖励
  received: boolean
}

const REGION_STAGES_COUNT = 10
const REGION_CHALLENGE_STAGE_INDEXS = [3, 6, 10]
const REGION_COLORS = [
  'ld-brand',
  'cyan',
  'purple',
  'teal',
  'blue',
  'green',
] as const

const store = useCommonStore()
const scroller = ref<any>()
const root = ref<HTMLDivElement>()
const focusBtn = ref<'up' | 'down' | 'none'>('none')
const rewardOverlay = ref()
const scrollerKey = ref(0)
const selectedChallengeStageRegion = ref<Region>()
const selectedChallengeStage = ref<ChallengeStage>()

const preChallengeStageInfo = ref<ChallengeStagePreInfo>({
  requiredEnergy: 0,
  exp: 0,
})

const scrollTop = ref(0)

const loading = ref(false)
const stages = ref<Stage[]>([])

// 按照从小到大排序
const regions = ref<Region[]>([])

const scrollItems = computed(() => {
  // size 需要包括 margin
  return [
    { type: 'unlock', size: 224, index: 'unlock' },
    ...regions.value
      .slice()
      .reverse()
      .map(region => ({ ...region, size: 1113, type: 'region' })),
    { type: 'startHere', size: 223, index: 'startHere' },
  ]
})

// 当前用户所查看的 region
const activeRegion = ref<Region>()

const taskStats = ref<TaskStats>()
const challengeStageStartLoading = ref(false)
const lastSelectedPackageId = ref<number>()

const latestRegion = computed(() => last(regions.value))
const latestRegionLastStage = computed(() => last(latestRegion.value?.stages))

const allChallengeStages = computed(() => {
  return regions.value.reduce<ChallengeStage[]>((acc, item) => {
    return acc.concat(item.challengeStages)
  }, [])
})

// called on initial mount
onActivated(() => {
  nextTick(() => {
    fetchDailyTasks()
    store.fetchEnergyStatus()
    handleQuery()
  })
})

onMounted(() => {
  bus.on(BusEvent.StageUnitComplete, fetchStages)
})
onUnmounted(() => {
  bus.off(BusEvent.StageUnitComplete, fetchStages)
})

async function fetchStages() {
  if (loading.value) return

  loading.value = true
  try {
    const res = await fetchAtlasStages()
    const challengeRes = await fetchAtlasChallengeStages()

    lastSelectedPackageId.value = last(res.stages)?.pkgInfo?.id

    stages.value = []
    regions.value = []

    stages.value = res.stages.map(item => {
      return {
        level: item.level,
        active: item.completedAt == null,
        completed: item.completedAt != null,
        atlasStage: item,
      }
    })

    if (!last(stages.value)?.active) {
      stages.value.push({
        level: res.stages.length + 1,
        active: true,
        completed: false,
      })
    }

    generateRegions(stages.value, challengeRes.stages)
    fetchRegionRewards()
  } finally {
    loading.value = false

    nextTick(() => {
      focusActiveStage()
      handleQuery()
    })
  }
}

async function fetchDailyTasks() {
  store.fetchUnreceivedTaskCount()

  const stats = await fetchTasks()
  taskStats.value = stats
}

function onGetMoreRewards() {
  router.push({ name: 'reward' })
}

function regionTitle(region: Region): string {
  const firstStage = first(region.stages)
  const lastStage = last(region.stages)

  return _t('atlas.region_title', {
    region: region.index + 1,
    firstLevel: firstStage?.level,
    lastLevel: lastStage?.level,
  })
}

function getRegionStar(region: Region): number {
  return region.challengeStages.reduce((acc, item) => acc + item.star, 0)
}

function getRegionTotalStar(region: Region): number {
  return region.challengeStages.length * 3
}

function getRegionPercent(region: Region): number {
  return (getRegionStar(region) / getRegionTotalStar(region)) * 100
}
function generateRegions(
  stages: Stage[],
  challengeStageResponses: AtlasChallengeStage[]
) {
  let regionStages: Stage[] = []
  let regionIndex = 0

  for (const stage of stages) {
    regionStages.push(stage)

    if (regionStages.length === REGION_STAGES_COUNT) {
      regions.value.push({
        color: REGION_COLORS[regionIndex % REGION_COLORS.length],
        index: regionIndex,
        stages: regionStages,
        challengeStages: [],
        received: false,
      })
      regionIndex++
      regionStages = []
    }
  }

  if (regionStages.length > 0) {
    regions.value.push({
      index: regionIndex,
      color: REGION_COLORS[regionIndex % REGION_COLORS.length],
      stages: regionStages,
      challengeStages: [],
      received: false,
    })
  }

  const lastRegion = last(regions.value) as Region

  lastRegion.stages.push(
    ...Array.from({
      length: REGION_STAGES_COUNT - lastRegion.stages.length,
    }).map((_, i) => {
      const startLevel = lastRegion.index * REGION_STAGES_COUNT

      return {
        level: startLevel + lastRegion.stages.length + i + 1,
        active: false,
        completed: false,
      }
    })
  )

  let index = 1
  for (const region of regions.value) {
    for (const challengeIndex of REGION_CHALLENGE_STAGE_INDEXS) {
      const preStage = region.stages[challengeIndex - 1]
      const response = challengeStageResponses.find(
        item => item.index === index
      )

      region.challengeStages.push({
        active: preStage?.completed,
        index,
        level: preStage.level,
        npcImage: `10${(((index - 1) % 12) + 1).toString().padStart(2, '0')}`,

        star: response?.star ?? 0,
        lastCompletedAt: response?.lastCompletedAt,
      })

      index++
    }
  }

  activeRegion.value = last(regions.value)
}

async function fetchRegionRewards() {
  await fetchAtlasRegionRewardStatus(regions.value.map(r => r.index + 1)).then(
    res => {
      for (const index in res) {
        regions.value[Number(index) - 1].received = true
      }
    }
  )
}

function handleQuery() {
  // 从卡包列表直接跳转过来
  if (route.query.pkgId) {
    router.replace({
      query: {
        ...route.query,
        pkgId: undefined,
      },
    })
    const stage = stages.value.find(item => item.active)
    if (stage) {
      onClickStage(stage, Number(route.query.pkgId))
    }
    return
  }

  if (route.query.to === 'recommend-list') {
    router.replace({
      query: {
        ...route.query,
        to: undefined,
      },
    })

    _presentContent(RecommendDialog, {
      rootClass: 'h-680px w-480px',
      bottomSheetClass: 'h-85vh',
      props: {
        navigator: _global.isPcMode ? 'modal' : 'bottomSheet',
        onPackageChallenge(pkgId: number) {
          _closeAllDialogs()
          const stage = stages.value.find(item => item.active)
          if (stage) {
            onClickStage(stage, Number(pkgId))
          }
        },
      },
      dialog: {
        pt: {
          content: {
            style: 'padding: 0px;',
          },
        },
      },
    })

    return
  }
}

function onScroll(e: UIEvent) {
  const target = e.target as HTMLDivElement
  const containerRect = target.getBoundingClientRect()
  scrollTop.value = target.scrollTop

  for (let i = regions.value.length - 1; i >= 0; i--) {
    const region = regions.value[i]
    const el = root.value!.querySelector(
      `#region-${region.index}`
    ) as HTMLDivElement
    if (el != null) {
      const rect = el.getBoundingClientRect()

      if (rect.bottom > containerRect.top + 66) {
        activeRegion.value = region
        break
      }
    }
  }

  adjustFocusBtn()
}

onInit(() => {
  fetchStages()
  fetchDailyTasks()
})

function onClickCompletedStage(stage: Stage) {
  _openDialog(StageReview, {
    rootClass: 'w-[90vw] max-w-600px p-4',
    props: {
      stage: stage.atlasStage,
    },
    dialog: {
      showHeader: false,
      dismissableMask: true,
      pt: {
        content: {
          class: 'bg-[unset] pb-2',
          style: 'display: block; padding: 0px;',
        },
      },
    },
  })
}

function onRegionRewardOverlayToggle(evt: any) {
  rewardOverlay.value!.toggle(evt)
}

// 区域奖励领取中
let isRegionRewardClaiming = false
function onRegionRewardReceive(evt: any) {
  let isFullStar =
    getRegionStar(activeRegion.value!) ===
    getRegionTotalStar(activeRegion.value!)

  // 如果未收集满 ⭐️ ，则弹出 overlay 提示
  if (!isFullStar) {
    onRegionRewardOverlayToggle(evt)
    return
  }

  if (isRegionRewardClaiming) return
  isRegionRewardClaiming = true
  fetchAtlasRegionReward(activeRegion.value!.index + 1)
    .then(res => {
      if (res.code === Code.Ok) {
        activeRegion.value!.received = true
        _showRewards([{ type: RewardType.DIAMOND, value: 20 }])
      } else {
        _message.info(res.message)
      }
    })
    .finally(() => {
      isRegionRewardClaiming = false
    })
}

async function onClickStage(stage: Stage, pkgId?: number) {
  if (!stage.active) {
    _message.info('请先完成当前关卡')
    return
  }

  const selectedPkgId = await _openDialog<number>(PkgSelector, {
    rootClass: 'g-dialog px-0 pb-2 border-none',
    props: {
      stageLevel: stage.level,
      lastPkgId: pkgId ?? lastSelectedPackageId.value,
      onStageCompleted() {
        fetchStages()
        fetchDailyTasks()
        // 完成关卡 检查是否需要展示通知引导
        if (_global.isInsideApp) {
          checkNotificationGuide()
        }
      },
    },
    dialog: {
      showHeader: false,
      pt: {
        content: {
          class: 'bg-[unset] pb-2',
          style: 'display: block; padding: 0px;',
        },
      },
    },
  })

  if (selectedPkgId != null) {
    lastSelectedPackageId.value = selectedPkgId
  }
}

function getRegionStyle(region: Region) {
  const styleList: string[] = []

  const color: string = region.color

  if (region.received) {
    styleList.push(`border-bottom-width: 4px`)
  } else {
    styleList.push(`background-color: var(--${color}-500)`)
    styleList.push(`border-color: var(--${color}-200)`)
    styleList.push(`box-shadow: 0px 4px var(--${color}-600)`)
  }

  return styleList.join(';')
}

async function onClickChallengeStage(
  region: Region,
  challengeStage: ChallengeStage
) {
  if (!challengeStage.active) {
    _message.info(`通过第 ${challengeStage.level} 关后可挑战`)
    return
  }

  const params = getChallengeStageParams(challengeStage)
  const res = await preStartAtlasChallengeStage(params)
  preChallengeStageInfo.value = res

  selectedChallengeStageRegion.value = region
  selectedChallengeStage.value = challengeStage
}

async function onDebugChallengeStageFinish(
  challengeStage: ChallengeStage,
  star: 1 | 2 | 3
) {
  if (challengeStageStartLoading.value) return
  const allChallengeStages = regions.value.reduce<ChallengeStage[]>(
    (acc, item) => {
      return acc.concat(item.challengeStages)
    },
    []
  )

  const index = allChallengeStages.indexOf(challengeStage)
  const prevChallengeStage = allChallengeStages[index - 1]
  const endLevel = challengeStage.level
  const startLevel = prevChallengeStage?.level ?? 1
  try {
    challengeStageStartLoading.value = true
    await debugFinishChallengeStage(
      challengeStage.index,
      startLevel,
      endLevel,
      star
    )
    selectedChallengeStage.value = undefined
    fetchStages()
    fetchDailyTasks()
    store.fetchStatsOverview()
    store.fetchEnergyStatus()
    store.fetchUserAssets()
  } finally {
    challengeStageStartLoading.value = false
  }
}

function getChallengeStageParams(challengeStage: ChallengeStage) {
  const index = allChallengeStages.value.findIndex(
    item => item.index === challengeStage.index
  )
  const prevChallengeStage = allChallengeStages.value[index - 1]
  const endLevel = challengeStage.level

  // 起始关卡为上一个挑战关卡的 level + 1
  const startLevel =
    prevChallengeStage == null ? 1 : prevChallengeStage.level + 1
  return {
    index: challengeStage.index,
    startLevel,
    endLevel,
  }
}

async function onChallengeStageStart(challengeStage: ChallengeStage) {
  if (challengeStage.active && !challengeStageStartLoading.value) {
    challengeStageStartLoading.value = true

    try {
      const params = getChallengeStageParams(challengeStage)
      const res = await startAtlasChallengeStage(params)

      if (res.code === Code.NotEnoughEnergy) {
        _message.info('面包不足')
        showEnergyDialog(true)
        return
      }

      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      store.increaseEnergy(-preChallengeStageInfo.value.requiredEnergy)

      store.setChallengeStageUnit(
        {
          unitId: res.data.unitId,
          atlasStageId: res.data.stageId,
          schedules: res.data.schedules,
        },
        {
          index: challengeStage.index,
          npcName: challengeStage.npcImage,
        }
      )

      selectedChallengeStage.value = undefined
      router.push({
        name: 'unit/learn',
      })
    } finally {
      challengeStageStartLoading.value = false
    }
  }
}

function adjustFocusBtn() {
  if (scroller.value == null) return

  const activeStageDom = root.value!.querySelector('.stage.active .anchor')
  const rect = activeStageDom?.getBoundingClientRect()

  if (rect == null) return

  if (rect.top < 0) {
    focusBtn.value = 'up'
  } else if (rect.bottom > window.innerHeight) {
    focusBtn.value = 'down'
  } else {
    focusBtn.value = 'none'
  }
}

function onClickFocusBtn() {
  focusActiveStage()
}

function focusActiveStage() {
  if (scroller.value == null) {
    return
  }

  // 触发 DOM 重新更新
  // 否则的话，在 iOS 上，当滚动的时候调用 scrollToItem 方法，回导致白屏
  scrollerKey.value++
  nextTick(() => {
    scroller.value.scrollToItem(1)

    // count 是以防万一，做一下保护，避免无限制调用 requestAnimationFrame
    let count = 0
    function focusStage() {
      count++
      if (count >= 100) return
      const activeStageDom = root.value!.querySelector('.stage.active .anchor')
      if (activeStageDom == null) {
        requestAnimationFrame(focusStage)
        return
      }
      activeStageDom.scrollIntoView()
    }
    nextTick(focusStage)
  })
}

function showRegionRewardButton() {
  const stageLevel3 = stages.value.find(item => item.level === 3)

  return stageLevel3?.completed
}

watch(
  () => store.user,
  user => {
    if (user != null) {
      fetchStages()
    }
  }
)
</script>
<style scoped>
.big-screen {
  .main {
    display: flex;
    gap: 48px;
    justify-content: center;
  }

  .atlas-header {
    height: 0px;
    overflow: hidden;
    /* TODO(buding): 这里直接用 display none 会导致每日任务那里的数字 svg 渲染异常 */
    /* display: none; */
  }

  .tasks-header.header {
    display: flex;
    margin-bottom: 24px;
  }

  .tasks {
    display: block;
  }
}

.daily-tasks {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px var(--slate-300);
}

.daily-tasks :deep(.daily-task) {
  box-shadow: none;
}

.region-header {
  height: 70px;
  border-radius: 12px;
  margin: 0px 8px 4px;
  display: flex;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
}

.regions-main {
  flex: 1;
  overflow: auto;
  position: relative;
}

.unlock-region {
  height: 200px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--ld-border);
  background: var(--gray-100);
  border-radius: 16px;
  padding: 16px 0px;
  margin: 12px 16px;
}

.focus-btn {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
  border: 1px solid var(--ld-border);
  background-color: white;
  border-bottom: 4px solid var(--ld-border);
  border-radius: 12px;
  z-index: 1;
}

.daily-tasks .task-list {
  gap: 0px;
}
</style>
